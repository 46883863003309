<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    <div class="container">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-6">
                <div class="product-detail">
                  <div class="tab-content" id="v-pills-tabContent">
                    <div
                      class="tab-pane fade active show"
                      id="product-4"
                      role="tabpanel"
                    >
                      <div class="product-img">
                        <img
                          src="../../../../assets/images/product/main/production_equipement/Selective_Soldering/csm_Ersa-ECOSELECT1-01-900x675_7504332b26.png"
                          alt="img-4"
                          class="img-fluid mx-auto d-block"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
              <div class="col-xl-6">
              <br><br><br>
              <div class="row">
                <div class="col-2"></div>
                <div class="mt-1 col-6">
                  <h1 class="font-size-48 mb-3">
                    <strong> Product description: </strong>
                  </h1>
                  <h2>
                 Small Selective Soldering System with outstanding technology. 
                  </h2>
                </div>
                </div>
              </div>
              
            </div>
            <!-- end row -->
            <br /><br /><br />
            <div class="row">
              <div class="col-xl-6">
                <br /><br />
                <div class="mt-4">
                  <h1>Tech-Highlights ECOSELECT 1</h1>
                  <br />
                  <ul class="container">
                    <li>
                      <h4>
                      Compact batch system with small space requirement
                      </h4>
                    </li>
                    <br />
                    <li>
                      <h4>Same components and software modules from the well-known VERSAFLOW 3 platform</h4>
                    </li>
                      <br />
                    <li>
                      <h4>Highest quality of the products, also in the entry level of selective soldering</h4>
                    </li>
                   
                    <br />
                  </ul>
                </div>
              </div>
              <div class="col-xl-6">
                <div class="product-detail">
                  <div class="tab-content" id="v-pills-tabContent">
                    <div
                      class="tab-pane fade active show"
                      id="product-4"
                      role="tabpanel"
                    >
                      <div class="product-img">
                        <img
                          src="../../../../assets/images/product/main/production_equipement/Selective_Soldering/21csm_Ersa-Selective-SMARTFLOW-006-900x600_ae6f26d723.jpg"
                          alt="img-4"
                          class="img-fluid mx-auto d-block"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
            </div>
            <!-- end row -->
            <br /><br /><br /><br /><br />

            <div class="row">
              <div class="col-8" style="margin-left: 50px">
                <h1><strong>Technical data</strong></h1>
              </div>
              <div class="col-3"></div>
            </div>
            <br />
            <b-tabs pills card vertical style="font-size: 18px">
              <b-tab active title="Dimensions">
                <div class="row" style="margin-bottom: 5px; margin-left: 10%">
                  <div class="col">
                    <h4>Length: 1,700 mm</h4>
                  </div>
                  <div class="col">
                    <h4>Height: 1,612 mm</h4>
                  </div>
                </div>
                <div class="row" style="margin-left: 10%">
                  <div class="col"><h4>Width: 1,500 mm</h4></div>
                  <div class="col"></div>
                </div>
              </b-tab>

              <b-tab title="Conveyor support">
                <div class="row" style="margin-left: 10%">
                  <div class="col">
                    <h4>Universal mask holder</h4>
                  </div>
                  <div class="col">
                    <h4>Maximum PCB build-up at the top up to 120 mm</h4>
                  </div>
                </div>
                <div class="row" style="margin-left: 10%">
                  <div class="col"><h4>PCB width: 15-406 mm (optional 508 mm)</h4></div>
                  <div class="col">
                    <h4>Maximum PCB build-up bottom up to 60 mm (30 mm to z-variable)</h4>
                  </div>
                </div>
                <div class="row" style="margin-left: 10%">
                  <div class="col"><h4>PCB length: 15-508 mm</h4></div>
                  <div class="col">
                    <h4>Maximum PCB weight: 8 kg (optional 12 kg)</h4>
                  </div>
                </div>
              </b-tab>
              <b-tab title="Flux / Preheat / Solder Module">
                <div class="row" style="margin-left: 10%">
                  <div class="col">
                    <h4>
                      <strong>Fluxer:</strong> Drop-Jet in different sizes
                    </h4>
                  </div>
                  <div class="col">
                    <h4><strong> Soldering module:</strong></h4>
                  </div>
                </div>

                <div class="row" style="margin-left: 10%">
                  <div class="col">
                    <h4>
                      <strong> Preheat module:</strong> IR heating , convection  or combination of IR and convection
                    </h4>
                  </div>
                  <div class="col"><h4>Electromagnetic solder pot</h4></div>
                </div>

                   <div class="row" style="margin-left: 10%">
                  <div class="col">
                    <h4></h4>
                  </div>
                  <div class="col"><h4>Mini-wave 13 kg, up to 2 pots</h4></div>
                </div>
                
               
              </b-tab>
            </b-tabs>

          

            <br /><br />
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
};
</script>